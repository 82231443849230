import type { Router } from 'vue-router'
import { resetStore, resetStoreGuard } from '~/libs/storeSetting'
import LogoutApiService from '~/services/LogoutApiService'

export async function logout (router: Router) {
  await LogoutApiService.logout().then(async resStatus => {
    if (resStatus != 200) {
      // TODO: エラー
      return
    }
    sessionStorage.clear()
    resetStore()
    resetStoreGuard()
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      const eqPos = cookie.indexOf("=")
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie
      document.cookie = name + '=; max-age=0'
    }
    await router.push('/login')
  })
}
